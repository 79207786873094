@use 'base';

.done {
  &-page {
    background: base.$color-primary;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    align-items: stretch;

    &-details {
      padding: 4rem 0;
      display: grid;
      grid-template-columns: 1fr 4fr;

      > .pointer :hover {
        color: var(--done-color-00ff89);
      }
    }

    &-header {
      &-first {
        flex-basis: 100%;
        padding: 11.2rem 3.2rem 3.2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        &-stories {
          background-image: linear-gradient(
              rgba(0, 0, 0, 0.541) 0%,
              rgba(0, 0, 0, 0.158) 100%
            ),
            url('../assets/img/stories_photo.webp');
          background-position: center bottom;
          background-size: cover;
        }

        &-enterprises {
          background-image: linear-gradient(
              rgba(0, 0, 0, 0.541) 0%,
              rgba(0, 0, 0, 0.158) 100%
            ),
            url('../assets/img/section-entreprises.webp');
          background-position: center;
          background-size: cover;
        }
      }

      &-title {
        text-transform: uppercase;
      }

      &-second {
        flex-basis: 50%;
        overflow: hidden;
        display: none;

        img {
          min-width: 100%;
        }
      }

      &-paragraph {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-20) /
          var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
        letter-spacing: var(--done-character-spacing--0-22);
        text-align: left;
        margin: 3.2rem 0;

        &-italic {
          font-style: italic;
        }
      }
    }

    &-stories {
      &-titles {
        display: flex;
      }

      &-arrow {
        width: 7.3rem;
        flex-shrink: 0;
        margin-right: 1.8rem;

        &-see-more {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-16) /
            var(--done-line-spacing-20) var(--done-font-family-signal-mono);
          width: 7.3rem;
          display: flex;
          justify-content: center;
          white-space: nowrap;

          &::before {
            content: 'VOIR PLUS';
          }

          &.stories-see-less {
            &::before {
              content: 'VOIR MOINS';
            }
          }
        }
      }

      &-destination {
        h3,
        &-title {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-32) /
            var(--done-line-spacing-28) var(--done-font-family-signal-mono);
          letter-spacing: var(--done-character-spacing--0-32);
          margin-bottom: 2.9rem;
        }

        p {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-medium) var(--done-font-size-20) /
            var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
          letter-spacing: var(--done-character-spacing--0-2);
        }
      }
    }
  }

  &-section {
    &-tiles {
      display: flex;
      flex-wrap: wrap;
    }

    &-story {
      width: 100%;
      display: flex;
      margin-bottom: 3.2rem;

      h3 {
        overflow-wrap: normal;
        word-break: keep-all;
      }

      img {
        width: 100%;
      }

      &-iframe {
        width: 100%;
        aspect-ratio: 4/3;
        position: relative;
        max-height: 650px;
      }

      &-reverse {
        flex-direction: column-reverse;
      }

      &-original {
        flex-direction: column-reverse;
      }
    }
  }

  &-simulation {
    &-title {
      max-width: 400px;
    }
  }
}

@media only screen and (min-width: base.$large-devices) {
  .done {
    &-page {
      align-items: stretch;

      &-details {
        padding: 4rem;
      }

      &-header {
        &-first {
          flex-basis: 50%;
          padding: 25rem 14rem 3.2rem;
          height: 100%;

          &-stories,
          &-enterprises {
            background-image: none;
          }
        }

        &-second {
          display: block;

          &-stories {
            background-image: linear-gradient(
                rgba(0, 0, 0, 0.541) 0%,
                rgba(0, 0, 0, 0.158) 100%
              ),
              url('../assets/img/stories_photo.webp');
            background-size: cover;
            background-position: center bottom;
          }

          &-enterprises {
            background-image: linear-gradient(
                rgba(0, 0, 0, 0.541) 0%,
                rgba(0, 0, 0, 0.158) 100%
              ),
              url('../assets/img/section-entreprises.webp');
            background-position: center;
            background-size: cover;
          }

          position: relative;
        }
      }

      &-stories {
        justify-content: space-between;
        margin: 3.2rem 0;
        padding: 0 5rem;

        &-arrow {
          flex: 1;
          width: 13.3rem;
          flex-shrink: 0;
          margin-right: 6.2rem;

          &-see-more {
            width: 13.3rem;
          }
        }

        &-destination {
          h3,
          &-title {
            font: var(--done-font-style-normal) normal
              var(--done-font-weight-normal) var(--done-font-size-32) /
              var(--done-line-spacing-28) var(--done-font-family-signal-mono);
            letter-spacing: var(--done-character-spacing--0-32);
            margin-bottom: 2.9rem;
            line-height: var(--done-line-spacing-30);
          }

          p {
            font: var(--done-font-style-normal) normal
              var(--done-font-weight-medium) var(--done-font-size-20) /
              var(--done-line-spacing-28)
              var(--done-font-family-pp-neue-montreal);
            letter-spacing: var(--done-character-spacing--0-2);
          }
        }
      }
    }

    &-section {
      &-tiles {
        display: flex;
        flex-wrap: wrap;
      }

      &-more {
        padding-top: 1rem;
      }

      &-story {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 0;

        img {
          width: 100%;
        }

        &-original {
          flex-direction: column;
        }

        &-reverse {
          flex-direction: column-reverse;

          & .done-page-details {
            order: 1;
          }
        }
      }
    }
  }
}
