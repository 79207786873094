@font-face {
  font-family: PP Neue Montreal;
  src: url("PPNeueMontreal-Regular.5e0546b4.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: PP Neue Montreal;
  src: url("Neue-Montreal-Medium.0edc0724.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: PP Neue Montreal;
  src: url("Neue-Montreal-Bold.836b8798.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Signal Mono;
  src: url("Signal Mono-Regular-Web.b0c13c96.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

.done-page {
  background: #333834;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  overflow: hidden;
}

.done-page-details {
  grid-template-columns: 1fr 4fr;
  padding: 4rem 0;
  display: grid;
}

.done-page-details > .pointer :hover {
  color: var(--done-color-00ff89);
}

.done-page-header-first {
  flex-direction: column;
  flex-basis: 100%;
  justify-content: space-evenly;
  padding: 11.2rem 3.2rem 3.2rem;
  display: flex;
}

.done-page-header-first-stories {
  background-image: linear-gradient(#0000008a 0%, #00000028 100%), url("stories_photo.b24e66e3.webp");
  background-position: bottom;
  background-size: cover;
}

.done-page-header-first-enterprises {
  background-image: linear-gradient(#0000008a 0%, #00000028 100%), url("section-entreprises.9d6aaae4.webp");
  background-position: center;
  background-size: cover;
}

.done-page-header-title {
  text-transform: uppercase;
}

.done-page-header-second {
  flex-basis: 50%;
  display: none;
  overflow: hidden;
}

.done-page-header-second img {
  min-width: 100%;
}

.done-page-header-paragraph {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
  letter-spacing: var(--done-character-spacing--0-22);
  text-align: left;
  margin: 3.2rem 0;
}

.done-page-header-paragraph-italic {
  font-style: italic;
}

.done-page-stories-titles {
  display: flex;
}

.done-page-stories-arrow {
  flex-shrink: 0;
  width: 7.3rem;
  margin-right: 1.8rem;
}

.done-page-stories-arrow-see-more {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-16) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
  white-space: nowrap;
  justify-content: center;
  width: 7.3rem;
  display: flex;
}

.done-page-stories-arrow-see-more:before {
  content: "VOIR PLUS";
}

.done-page-stories-arrow-see-more.stories-see-less:before {
  content: "VOIR MOINS";
}

.done-page-stories-destination h3, .done-page-stories-destination-title {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-32) / var(--done-line-spacing-28) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-32);
  margin-bottom: 2.9rem;
}

.done-page-stories-destination p {
  font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-20) / var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
  letter-spacing: var(--done-character-spacing--0-2);
}

.done-section-tiles {
  flex-wrap: wrap;
  display: flex;
}

.done-section-story {
  width: 100%;
  margin-bottom: 3.2rem;
  display: flex;
}

.done-section-story h3 {
  overflow-wrap: normal;
  word-break: keep-all;
}

.done-section-story img {
  width: 100%;
}

.done-section-story-iframe {
  aspect-ratio: 4 / 3;
  width: 100%;
  max-height: 650px;
  position: relative;
}

.done-section-story-reverse, .done-section-story-original {
  flex-direction: column-reverse;
}

.done-simulation-title {
  max-width: 400px;
}

@media only screen and (width >= 992px) {
  .done-page {
    align-items: stretch;
  }

  .done-page-details {
    padding: 4rem;
  }

  .done-page-header-first {
    flex-basis: 50%;
    height: 100%;
    padding: 25rem 14rem 3.2rem;
  }

  .done-page-header-first-stories, .done-page-header-first-enterprises {
    background-image: none;
  }

  .done-page-header-second {
    display: block;
    position: relative;
  }

  .done-page-header-second-stories {
    background-image: linear-gradient(#0000008a 0%, #00000028 100%), url("stories_photo.b24e66e3.webp");
    background-position: bottom;
    background-size: cover;
  }

  .done-page-header-second-enterprises {
    background-image: linear-gradient(#0000008a 0%, #00000028 100%), url("section-entreprises.9d6aaae4.webp");
    background-position: center;
    background-size: cover;
  }

  .done-page-stories {
    justify-content: space-between;
    margin: 3.2rem 0;
    padding: 0 5rem;
  }

  .done-page-stories-arrow {
    flex: 1 0;
    width: 13.3rem;
    margin-right: 6.2rem;
  }

  .done-page-stories-arrow-see-more {
    width: 13.3rem;
  }

  .done-page-stories-destination h3, .done-page-stories-destination-title {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-32) / var(--done-line-spacing-28) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-32);
    line-height: var(--done-line-spacing-30);
    margin-bottom: 2.9rem;
  }

  .done-page-stories-destination p {
    font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-20) / var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
    letter-spacing: var(--done-character-spacing--0-2);
  }

  .done-section-tiles {
    flex-wrap: wrap;
    display: flex;
  }

  .done-section-more {
    padding-top: 1rem;
  }

  .done-section-story {
    grid-template-columns: 50% 50%;
    gap: 0;
    display: grid;
  }

  .done-section-story img {
    width: 100%;
  }

  .done-section-story-original {
    flex-direction: column;
  }

  .done-section-story-reverse {
    flex-direction: column-reverse;
  }

  .done-section-story-reverse .done-page-details {
    order: 1;
  }
}

/*# sourceMappingURL=index.bc1511e8.css.map */
