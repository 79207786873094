// _base.scss

@font-face {
  font-family: PP Neue Montreal;
  src: url('../assets/font/PPNeueMontreal-Regular.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: PP Neue Montreal;
  src: url('../assets/font/Neue-Montreal-Medium.woff2') format('woff2');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: PP Neue Montreal;
  src: url('../assets/font/Neue-Montreal-Bold.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Signal Mono;
  src: url('../assets/font/Signal Mono-Regular-Web.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
}

$color-success: #00ff89;
$color-primary: #333834;
$color-secondary: #eaeaf6;
$color-white: #ffffff;
$color-black: #000000;
$color-gray: #808080;

$x-small-devices: 368px;
$small-devices: 576px;
$medium-devices: 768px;
$large-devices: 992px;
$x-large-devices: 1200px;
$xx-large-devices: 1480px;
